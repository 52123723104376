@import "sisi-variables";
/*all input that have type button*/
input[type='button'], input[type='submit'] {
  text-align: left;
}
.control-label.sisi-label-align-left {
  text-align: left;
}

.control-label.sisi-label-align-right {
  text-align: right;
}
.sisi-aside-left .checkbox{
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}
.checkbox.sisi-spacer-pin-checkbox {
  padding-top: 5rem;
  padding-bottom: 4rem;
}

.sisi-spacer-pin-checkbox {
  padding-top: 5rem;
  padding-bottom: 10rem;
}

.sisi-label-align-left {
  padding-left: 0;
}
.btn-lg{
  margin-top:2rem;
}
input{
  outline:none;
}
.form-control {
  border-color: @ci-cta-color;
  border-radius: 0;
  box-shadow: none;
  transition: none;
  outline: none;
  margin-bottom:15px;
}

select.form-control {
  border-radius: 0px;
  box-shadow: none;
  outline: 0;
}

.btn-file {
  position: relative;
  overflow: hidden;
  width: 180px;
  height: 35px;
  padding: 5px 10px;
}

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.btn {
  border: none;
  height: 45px;
  box-shadow: none;
}

.red {
  background-color: @ci-cta-color;
  color: @font-color-white;
}
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
    text-align: left;
  }
}

#additional-people .name-group {
  margin-bottom: 0px;
}