.easyeditor-toolbar {

	border-bottom: 0;
	padding: 20px 0 0;
	user-select: none;

	ul:after {
		content: "";
		display: block;
		clear: both;
	}

	li {
		list-style: none;
		float: left;
		position: relative;
	}

	li ul {
		position: absolute;
		left: 0;
		top: 31px;
		z-index: 5;
		display: none;
	}

	li button {
		background: #c2c2c2;
		color: #fff;
		font-weight: bold;
		cursor: pointer;
		display: inline-block;
		padding: 8px 14px;
		float: left;
		min-width: 30px;
		outline: none;
		margin-right: 5px;
		margin-bottom: 0;

		&:hover {
			background: #9b9b9b;
		}
	}

	&.is-fixed {
		position: fixed;
		top: 0;
		background: #fff;
		border-bottom: 1px solid #ddd;
		z-index: 5;
	}
}

.easyeditor {

	outline: none;
	border: 1px solid #e9e9e9;
	padding: 10px;
	margin-bottom: 20px;
	overflow-y: auto;

	blockquote {
		border-left: 2px solid #e9e9e9;
		padding-left: 20px;
		color: #777;
	}

	figure {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 400px;
		min-width: 100px;
		max-width: 100%;
		padding: 7px;
		box-sizing: border-box;
		margin: auto;
		overflow: hidden;

		&.is-resizable {
			resize: both;
			border: 1px solid #0e90dc;
			cursor: se-resize;
		}

		img {
			width: 100%;
		}
	}

	ul,
	ol {
		margin-left: 30px;
		list-style-type: none;

		li:before {
			position: absolute;
			content: '–';
			margin-left: -20px;
		}
	}

	p {
		margin-bottom: 0;
	}

	&[contenteditable=true]:empty:before {
		content: attr(placeholder);
		display: block;
		opacity: 0.5;
		cursor: text;
	}
}


.easyeditor-temp {
	box-sizing: border-box;
	font-family: Consolas, monaco, monospace, "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter" !important;
	line-height: 18px;
	padding: 15px;
	border: 1px solid #e0e0e0;
}

.easyeditor-toolbar {

	ul {
		.btn-group;
	}

	button {
		.btn;
		.btn-default;
		.glyphicon;

		position: relative;
		float: left;
		margin-right: 2px;
		height: 38px;
		line-height: 1.8;

		span {
			display: none;
		}
	}

	.toolbar-bold {
		.glyphicon-bold;
	}
	.toolbar-italic {
		.glyphicon-italic;
	}
	.toolbar-ulist {
		.glyphicon-list;
	}
	.toolbar-quote {
		width: 42px;
		&:before {
			position: absolute;
			top: -23px;
			left: 8px;
			content: '“';
			font-size: 50px;
		}
	}

}
