@import "sisi-variables";
.tx-sisi-pinboard{
  .sisi-pinboard-office{
    font-size: 1.4rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
  }
  ul{
    border-bottom:solid thin @font-second-color;
  }
  border:0;

}
.sisi-pinboard-lp{
  .sisi-pinboard-office{
    margin-top:1.875rem;
    letter-spacing: 0;
  }
.sisi-pinboard-teilnahmebedingungen{
  text-align: right;
}
  .sisi-pinboard-img{
    margin-bottom:3rem;
  }

  img{
    width: auto;
    max-height: 400px;
    margin: auto;
    display: block;
  }
}
.sisi-pinboard-category-title{
  margin-top:1.875rem;
  letter-spacing: 0;
  color:@font-second-color;
}