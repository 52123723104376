.ie {
  .ce-gallery {
    max-width: 100%;
    .ce-outer, .ce-inner {
      max-width: 100%;
    }
    .ce-column {
      max-width: 100%;
      
      figure, figcaption {
        display: block;
      }
    }
  }
}