@import "sisi-variables.less";

.ui-widget p {
	font-family: "Source Sans Pro";
	font-size: 16px;
}

.ui-accordion {
	.ui-accordion-content {
		border: none;
	}
	.ui-accordion-header {
		background: transparent;
		border: none;
		border-bottom: solid thin;
		border-radius: 0;
		text-transform: initial;
		padding: 1rem 0;
	}
}

.t3ddy > .t3ddy-accordion-header {
	border-top: none;
}
.t3ddy-item{
  li a{
	line-height:25px;
  }
  a{
	font-family: "Source Sans Pro";
	border-bottom: solid thin @ci-cta-color;
  }
}
body .ui-accordion .ui-accordion-content {
	padding: 0;
	margin-left: 35px;
}

.ui-accordion-header + .ui-accordion-content-active + .ui-accordion-header {
	border-top: solid thin @font-second-color !important;
}

.sisi-content {
	.ui-accordion {
		font-family: "Source Sans Pro";
		> h2 {
			margin: -1px;
			border-top: none;
			border-bottom: solid thin;
			color: @font-main-color;
			padding-left: 40px;
		}
		.ui-accordion-header {
			.ui-icon {
				left: 0;
				top: 60%;
			}
			border-top-color: transparent;
			border-bottom-color: #cccccc;
			padding-top: 15px;
			padding-bottom: 15px;
		}
		.ui-accordion-content {
			border-top-color: transparent;
			border-bottom: 1px solid #cccccc;
			padding-top: 15px;
			padding-bottom: 15px;
			padding-left: 35px;
			margin-left: 0px;
		}
	}
	.ui-state-default {
		.ui-icon-triangle-1-s {
			background-image: url("../images/AKB-Menu-Expand.svg");
			background-repeat: no-repeat;
			background-size: 33px;
			background-position: 0 0;
			padding: 0px 0px 0px;
			height: 22px;
			width: 40px;
		  cursor: pointer;
			margin-left: -40px;
			vertical-align: top;
			display: inline-block;
		}
		.ui-icon-triangle-1-e {
			background-image: url("../images/AKB-Menu-Expand.svg");
			background-repeat: no-repeat;
			background-size: 33px;
			background-position: 0 0;
			padding: 0px 0px 0px;
			height: 22px;
			width: 40px;
		  cursor: pointer;
			margin-left: -40px;
			vertical-align: top;
			display: inline-block;
		}
	}
	.ui-state-active {
		.ui-icon-triangle-1-s {
			background-image: url("../images/AKB-Menu-Collapse.svg");
			background-repeat: no-repeat;
			background-size: 33px;
			background-position: 0 0;
			padding: 0px 0px 0px;
			height: 22px;
			width: 40px;
		  cursor: pointer;
			margin-left: -40px;
			vertical-align: top;
			display: inline-block;
		}
		.ui-icon-triangle-1-e {
			background-image: url("../images/AKB-Menu-Collapse.svg");
			background-repeat: no-repeat;
			background-size: 33px;
			background-position: 0 0;
			padding: 0px 0px 0px;
			height: 22px;
			width: 40px;
		  cursor: pointer;
			margin-left: -40px;
			vertical-align: top;
			display: inline-block;
		}
	}
	
}