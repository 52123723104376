@import "sisi-variables";
@import "variables";
.sisi-members-search-wrapper{

.btn{
  text-align: left;

}
  .dropdown-menu{
    margin-left:-3%;
    margin-right:-20px;
    padding-bottom: 0;
  }
  .sisi-headline-search{
    font-weight: bold;
    padding-bottom: .5rem;
    margin-top: 4rem;
  }
  .form-control{
    border:0;
  }
  .sisi-member-input{
    padding-left:0;
    margin-top: 5rem;
  }
  .radio, .checkbox{
    color:@font-main-color;
  }
  .btn-success{
    text-align: center;
  }
  label{
    width:100%;
  }
}
#membersearch{
   background-image: url('../images/Icon-AKB-Search-Members-White.svg');
    background-repeat: no-repeat;
   background-position: 95% center;
   text-align: left;


}
.sisi-headline-member{
  font-size:@font-medium;
  font-weight:300;
  padding-bottom:1.875rem;
}
.tx-sisi-members{
  ul{
    border-bottom: solid thin @font-second-color;
  }
}
.sisi-member-form{
  clear: both;
}
.sisi-bottom-search{
  background-color:@bg-color-white;
  border:solid thin @second-color;
  margin-bottom: 4rem;
  .navbar-left{
    width: 95%;
    .glyphicon-search{
      padding-top:8px;
    }
  }

  .form-group{
    padding-top:10px;
    width:95%;
  }
  input{
    border:none;
    width:100%;
  }
  img{
    padding-top:10px;
    position: absolute;
    right: 20px;
  }
}
.sisi-search-icon{
  cursor: pointer;
  display: block;
  float: left;
  margin-top: 26px;

}

#sisi-search-grey{
  .sisi-stroke-helper{
    stroke:#B0B0AF;
  }

}

#sisi-member-search-open{
  position: fixed;
  top:0;
  right:3px;
  height: 45px;
  width: 45px;
  cursor:pointer;
  background-color: @second-color;
  background-image: url('../images/Icon-AKB-Search-Members-White.svg');
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10;

}


.tx-indexedsearch-browsebox{
  li{
    display: inline;
    padding:5px;
  }
}


.tx-indexedsearch-rules {
  display: none;
}

.tx-indexedsearch-search-for {
  display: none;
}

.sisi-advanced-search {
  display: none;
}

.tx-indexedsearch-info {
  display: none;
}

.tx-indexedsearch-form label {
  display: none;
}

.tx-indexedsearch-icon {
  display: none;
}

.tx-indexedsearch-res {
  border-bottom: thin solid #999
}

@media (min-width: 992px)and (max-width:1280px){
  #sisi-member-search-open{
    right:0;
  }
}

@media (min-width: 1400px){
  #sisi-member-search-open{
    right:5%;
  }


}