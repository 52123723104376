@import "sisi-variables";
.tx-sisievents {
  .tx-sisievents-ul{
    padding-bottom: 2.875rem;
    letter-spacing: .1rem;
  }
  h1{
    color:@font-main-color;
  }
  .sisievents-media {
    padding-bottom: 15px;
    img {
      height: 220px;
      width: auto;
    }
  }
  .sisi-collapse{
    color: @font-second-color;
  }
  #eventgroup.padding-left25 {
    padding-left: 35px;
  }
}
.tx-sisi-eventcalender{
  .tx-sisievents-ul-border{
    color:@font-second-color;
    border-bottom:solid thin @font-second-color;
    padding-bottom:2.875rem;
  }
}

.sisi-event-rubrik{
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color:@font-main-color;
}

.tx-sisievents .typo3-messages {
  float: left;
}