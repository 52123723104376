@import "variables";
@import "sisi-variables";

@media screen and (max-width: @screen-md) {


  .sisi-nav-main{
	left:30%;
  }
  #membersearch{
	margin-right: 20px;
  }

	.wrapper-status {
		padding-left: 70px;
	}
	
	.sisi-pinboard-teilnahmebedingungen {
		padding-top: 20px
	}
	
	.sisi-pinboard {
		
		.form-horizontal {
			margin: 0;
			padding: 0;
		}
		
	}
	.sisi-bottom-search.row {
		margin-left: 20px;
		margin-right: 20px;
		position: relative;
	}
	
	.sisi-pinboard-datum, .sisi-pinboard-office {
		padding: 0;
	}
	
	.mobile-str-calender-wrapper {
		display: none;
	}
	
	.sisi-startseite-left {
		padding-left: 20px;
		
		.ce-textpic.ce-right {
			padding-left: 20px;
		}
	}
	
	.container {
		padding: 0;
	}
	
	.sisi-highlights-bg-color-wrapper {
		padding: 0;
	}
	
	.row {
		margin-left: 0;
		margin-right: 0;
	}
	
	.sisi-header {
		display: none;
	}
	
	.sisi-startseite-right {
		padding-left: 40px;
		.sisi-news-item {
			border: 0;
		}
	}
	
	.sisi-bottom-search img {
		padding-top: 0;
		
	}
	
	.tx-sisi-seminar .list-group, .tx-sisi-pinboard .list-group {
		padding-right: 0;
	}

	#mobile-main-navi {
		display: block;
	}

	.sisi-header-mobile {
		display: block;
		position: fixed;
		width: 100%;
		text-align: center;
		border-bottom: solid thin @font-second-color;
		z-index: 10;
		left: 0;
		height: 55px;
		background-color: @bg-color-white;
		
		.logo-img-mob {
			
			float: left;
			width: auto;
			margin-top: 10px;
			#logo-svg, #logo-svg2 {
				width: 100px;
				height: 55px;
			}
		}
		
		.logo-img-text {
			display: none;
			
		}
		.sisi-search-icon-mobile {
			padding: 0 10px 23px 20px;
			background-image: url('../images/Icon-AKB-Search-Grey.svg');
			background-repeat: no-repeat;
			background-position: center bottom;
			cursor: pointer;
			display: inline;
			background-size: 100%;
		}
		
		#navi-mobile {
			width: 55px;
			height: 55px;
			cursor: pointer;
			position: absolute;
			right: 0;
			background-repeat: no-repeat;
			background-size: 35px;
			background-position: center;
			
		}
		
		.navbar-nav > li > a {
			padding-top: 0;
			
		}
		
		.navi-mobile-menue {
			border-left: solid thin @font-second-color;
			background-image: url('../images/AKB-Menu.svg');
		}
		
		.navi-mobile-close {
			background-image: url('../images/AKB-Menu-Close.svg');
		}
		
		.navi-mobile-wrapper {
			padding-right: 0;
		}
	}
	
	.mobile-str-calender {
		display: block;
		width: 100%;
		height: 55px;
		position: absolute;
		top: 0;
		left: 0px;
		border-bottom: solid thin @font-second-color;
		border-top: solid thin @font-second-color;
	}
	
	.mobile-str-calender-icon {
		background-repeat: no-repeat;
		background-size: 40px 40px;
		background-repeat: no-repeat;
		background-image: url('../images/AKB-Menu-Calendar.svg');
		height: 100%;
		background-position: left 20px top 5px;
		
	}

	#sisi-member-search-open {
		display: none;
	}
	
	.tx-sisi-members-popup {
		padding-right: 0;
	}
	
	.navbar-nav {
		float: none;
		margin: 0;
	}
	
	#mobile-main-navi .sisi-sec-menu .sisi-li-last a {
		color: #e4002c;
	}
	
	#mobile-main-navi .sisi-sec-menu:hover {
		background-color: transparent;
		color: @font-second-color;
	}
	
	.navbar-nav > li > a:hover {
		background-color: transparent;
	}
	
	.sisi-seminar-datum, .sisi-headline, .sisi-seminar-rubrik {
		padding-left: 0
	}
	
	.sisi-nopadding-md {
		padding: 0
	}
	
}

@media screen and (max-width: @screen-sm) {


  #membersearch{
	margin-right: 0;
  }

  .sisi-mobile{
	display: block;
  }
  .sisi-normal{
	display: none;
  }
	.sisi-seminar-lp {
		.sisi-col {
			padding-left: 0px;
		}
	}
	
	.sisi-seminar-filter {
		form {
			border-bottom: solid thin @font-second-color;
			display: none;
			padding-bottom: 1rem;
		}
	}
	
	.padding-left25 {
		padding-left: 0;
		padding-top: 2rem;
	}
	
	.sisi-bottom-adress, .sisi-bottom-map {
		padding: 0;
	}
	
	.sisi-bottom-adress {
		margin-bottom: 25px;
	}
	
	body .nav > li > a {
		padding: 5px 0px;
	}
	
	.sisi-aside-left .checkbox {
		padding-left: 20px;
	}
	
	.sisi-aside-left select, .sisi-aside-left button {
		width: 90%;
		margin-left: auto;
		margin-right: auto;
	}
	
	.sisi-aside-left {
		padding-right: 0;
		padding-left: 0;
	}

	.sisi-aside-content {
		margin-top: 2rem;
		padding-right: (@grid-gutter-width / 2);
		padding-left: (@grid-gutter-width / 2);
	}
		
	.sisi-content {
		margin-top: 50px;
	}
	
	.mobile-str-calender-link {
		height: 100%;
		padding-top: 15px;
		background-repeat: no-repeat;
		background-position: 97% 10px;
	}
	
	.mobile-str-calender-wrapper {
		position: relative;
		display: block;
		width: 100%;
		height: 55px;
	}
	
	.tx-sisi-seminar form {
		margin-top: 1rem;
	}
	
	.sisi-seminar-filter-mobile-str {
		display: block;
		height: 55px;
		border-bottom: solid thin @font-second-color;
		
	}
	
	.sisi-seminar-filter-mobile-str-link {
		height: 100%;
		padding-top: 15px;
		background-repeat: no-repeat;
		background-position: 97% 10px;
	}
	
	.sisi-seminar-filter-mobile-str-icon {
		background-repeat: no-repeat;
		background-size: 35px 35px;
		background-position: left 20px top 10px;
		background-image: url('../images/AKB-Menu-Filter.svg');
		height: 100%;
		
	}
	
	.row {
		margin-left: 0;
		margin-right: 0;
	}
	
	h1,
	.h1 {
		font-size: 20px;
		font-weight: 300;
		padding-bottom: 2.2rem;
		font-family: 'Flama-Light';
		a {
			font-family: 'Flama-Light';
		}
	}
	
	h2,
	.h2 {
		font-size: 20px;
		font-family: 'Flama-Light';
		a {
			font-family: 'Flama-Light';
		}
	}
	
	h3,
	.h3 {
		font-size: 20px;
		font-family: 'Flama-Light';
		a {
			font-family: 'Flama-Light';
		}
	}
	
	h1, h2, h3 {
		margin-top: 10px;
		margin-bottom: 0px;
	}
	
	.sisi-contact-approach {
		padding: 20px;
	}
	
	.sisi-content.col {
		margin-top: 30px;
	}
	
	.calender {
		display: none;
	}
	
	.sisi-aside-left {
		margin-top: 0;
	}
	
	#sidebar-wrapper .sisi-li-last-level1,#sidebar-wrapper-mobile .sisi-li-last-level1  {
		
		background-repeat: no-repeat;
		background-position: 97% 10px;
		background-size: 30px 30px;
		
	}
	
	.sisi-down {
		background-image: url('../images/AKB-Menu-Expand.svg');
	}
	
	.sisi-up {
		background-image: url('../images/AKB-Menu-Collapse.svg');
	}
	
	.sidebar-navi-sub {
		
	}
	
	#sidebar-wrapper .sisi-li-last-level1 > a ,#sidebar-wrapper-mobile .sisi-li-last-level1 > a {
		color: @font-second-color;
	}
	
	#sidebar-wrapper .sisi-li-last-level1.aktive > a,#sidebar-wrapper-mobile .sisi-li-last-level1.aktive > a {
		color: @font-main-color;
	}
	
	#sidebar-wrapper .sidebar-navi-sub-level2,#sidebar-wrapper-mobile .sidebar-navi-sub-level2 {
		margin-top: 1rem;
	}
	
	#sidebar-wrapper li,#sidebar-wrapper-mobile li {
		border-bottom: none;
	}
	
	.sisi-RelatedFiles-link {
		padding-left: 0;
	}
	
	.default-bold {
		text-align: center;
	}
	
	.sisi-highlights-title {
		margin-bottom: 2.5rem;
		text-align: center;
	}
	
	.sisi-RelatedFiles {
		font-size: @font-small;
	}
	
	.sisi-highlights-bg-color-wrapper {
		padding-left: 0;
		padding-right: 0;
	}
	
	.sisi-highlights-bg-color {
		background-color: transparent;
	}
	
	.sisi-bottom-search.row {
		height: 45px;
		margin-right: 0;
		margin-left: 1%;
		position: relative;
		width: 94%;
		padding-left: 20px;
		.form-group {
			padding-top: 0;
			padding-left: 20px;
		}
		button {
			position: absolute;
			right: 0;
			top: 15%
		}
		.navbar-form {
			padding: 0;
		}
		img {
			position: static;
		}
	}
	
	.container {
		padding-left: 0px;
		padding-right: 0px;
	}
	
	#membersearch {
		width: 100%;
		padding-left: 20px;
		background-position: 85% center;
	}
	
	.sisi-footer.row {
		margin-left: 0;
		margin-right: 0;
	}
	
	#sidebar-wrapper .aktive-level1.sisi-down ,#sidebar-wrapper-mobile  .aktive-level1.sisi-down {
		
		background-image: url('../images/AKB-Menu-Expand.svg');
		
		ul {
			display: none;
		}
	}
	
	#sidebar-wrapper.sisi-li-first, #sidebar-wrapper-mobile.sisi-li-first  {
		padding-top: 20px;
	}
	
	#sidebar-wrapper .sisi-li-last-level1, #sidebar-wrapper-mobile .sisi-li-last-level1{
		background-position: 97% 5px;
		padding-left: 20px;
		
		ul {
			margin-left: 20px;
			padding-top: 10px;
		}
	}
	
	.sisi-navi-bottom-small {
		
		li {
			display: block;
			padding-left: 0;
			padding-right: 0;
		}
		ul.navbar-nav {
			margin: 0;
			padding: 0;
			float: left;
		}
	}
	
	.sisi-startseite-right-wrapper {
		padding: 0;
	}
	
	.wrapper-status {
		padding-left: 20px;
	}
	
}

@media screen and (max-width: @screen-xs) {
	
	.sisi-banner-Text {
		display: none;
		
	}
	
	.sisi-startseite-right {
		padding-left: 20px;
	}
	
	.sisi-aside-left {
		padding-left: 0;
		padding-right: 0;
	}

	.row {
		margin-left: 0;
		margin-right: 0;
	}
	
	.sisi-startseite-left, .sisi-startseite-right {
		padding-left: 0;
		padding-right: 0;
	}

	.sisi-startseite-left	.mobile-str-calender-wrapper {
			margin-left: -20px;
			margin-right: -20px;
			width: calc(100% + 40px);
	}
	
	.sisi-startseite-right .news-list-view {	
		padding-left: 20px;
		padding-right: 20px;
	}
	
	.no-padding-xs {
		padding: 0;
	}
	
	.list-group-item {
		padding-right: 0;
	}
	
	#page-1 .sisi-content {
		margin-top: 80px;
	}
	
	.sisi-bigest-font {
		font-size: 2.4rem;
		line-height: 3.4rem;
		white-space: initial;
	}
	
	.sisi-contact-approach {
		padding-left: 20px;
		padding-right: 20px;
	}
	
	.pagination a
	.sisi-bottom-adress {
		margin-bottom: 25px;
	}
	
	.pagination a {
		width: 10%;
	}
	
	.sisi-navi-bottom-small {
		padding: 5px 20px;
	}
	
	.sisi-text-wrapper {
		position: relative;
		width: auto;
		min-height: auto;
		background-color: #ffffff;
		opacity: 1;
		padding: 5px 20px;
		margin: -45px 20px 50px;
	}
	
	.sisi-text-wrapper h1 {
		margin-top: 5px;
		padding-bottom: 0.5rem;
	}
	
	.sisi-banner-Text {
		display: block;
	}
    
	.wrapper-status {
		padding-left: 20px;
	}


}

@media screen and (max-width: 1200px) {
	.sisi-navi-top-small {
		margin-left: 0;
	}
	
	.dropdown-menu > li > a {
		padding-left: 5px;
	}
	
	.nav > li > a {
		padding: 18px 8px;
	}
	
	.sisi-nav-main > div {
		padding-left: 15px;
	}
	#sisi-navbar {
		padding: 0px;
	}
}


@media screen and (max-width: 400px) {
	
	.nav-tabs > li > a {
		padding-left: 0;
		padding-right: 0;
	}
	
	.calender {
		width: 312px;
		
	}
	
	.day {
		width: 42px;
	}
	
	.pagihead {
		width: 200px;
	}
}
@media screen and (max-width: 1250px) {

  .container-fluid {
	padding-left: 0;
  }
}

@media screen and (max-width: 350px) {
	.calender {
		width: 250px;
	}
	
	.day {
		width: 35px;
		height: 35px;
	}
	
	.pagihead {
		width: 180px;
	}
	
	.sisi-pinboard-list-left, .sisi-pinboard-img {
		padding-left: 0;
	}
}


@media screen and (max-width: 1000px) and (max-width: 1280px) {
	.navbar-collapse {
		padding-left: 0px;
	}
}

@media (min-width: 750px) and (max-width:1200px){

.navbar-collapse{
  max-width: 550px;
 }
  
  .sisi-nav-main {
	left: 32%;
  }
}

@media (max-width: @screen-md-max){
	.sisi-news-item {
    border-bottom: 0px;

		& > .sisi-news-item-border {
			border-bottom: solid thin @font-second-color;	
		}
	}
}

@media (min-width: @screen-md-min) and (max-width: @screen-md-max){
  #sisi-navbar .dropdown-menu .sisi-li-first{
    padding-top: 70px;
	}
}