@import "sisi-variables";
@import "variables";

#mobile-main-navi{
  background-color:@bg-color-white;
  width: 100%;
  top:55px;
  left:0;
  z-index: 10;
  border-bottom:solid thin #cccccc;
  position: fixed;

.sisi-sec-menu{
  border-bottom: solid thin @font-second-color;
  height: 55px;
  li{
    padding-left:0;
    padding-top:10px;
  }
  li.sisi-li-first{
    padding-left:20px;
  }
  a{
    font-size:@font-small;
    color:@font-second-color;
  }

  .active{
    a {color:@ci-cta-color;}
  }

  a:hover{
    color:@ci-cta-color;
  }
}

  .sisi-navi-head-mobile > a{
      padding-left:40px;
  }

  .mobile-navi-inner {
    position: fixed;
    top: 55px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    z-index: 9;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    border-bottom: solid thin @font-second-color;

    li{
      width: 100%;
      padding: 15px 15px 15px 0;
      background-repeat: no-repeat;
      background-position: 97% 10px;
      background-size: 30px 30px;
      list-style: none;

      .mobile-navi-level2{
        display: none;
        background-color:@ci-cta-color ;
        margin-top: 10px;
        li{
          padding: 5px 0px 5px 30px;
        }
      }
      div{
        background-color: @ci-cta-color;
        width: 120%;
      }

      .sisi-mobile-navi{
        display: none;

        > li{
          padding-left:60px;
        }

        a{
          padding:10px;
        }
      }
    }
  }

  .sisi-down{
    background-image: url('../images/AKB-Menu-Expand.svg');
  }
  .sisi-up{
    background-image: url('../images/AKB-Menu-Collapse.svg');
  }
}

.mobile-top-navi{
  li{
  }
}


@media screen and (max-width: @screen-xs) {

  #mobile-main-navi{
    .sisi-navi-head-mobile > a{
      padding-left:20px;
    }
    .mobile-navi-inner li{
      .sisi-mobile-navi > li {
        padding:5px 15px 5px 40px;

      }
    }
    .sisi-li-first.sisi-if-sub, .sisi-li-last {
      padding:5px 15px 5px 0;
    }

  }
  .sisi-navi-bottom-small{
    display: block;
  }
}