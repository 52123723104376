.fal-securedownload {

	/* config */

	@line-height: 1.8em;
	@gutter: 30px;

	.TextOverflow {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	/* config end */

	.clearfix;
	margin-top: 3.5rem;
	font-family: 'Flama-Light', sans-serif;

	ul {
		clear: both;
	}

	li {
		list-style-type: none;
		line-height: @line-height;
		clear: both;
		color: #999999;
	}

	span {
		cursor: default;
	}

	.folder {
		position: relative;
		padding-left: @gutter;
		.TextOverflow;
		span, a {
			.TextOverflow;
		}
	}

	.file {
		position: relative;
		margin-left: @gutter;
		display: flex;
		flex-wrap: nowrap;
		align-items: flex-start;
		max-width: 500px;
		white-space: nowrap;

		> * {
			display: block;
			flex: 0 1 auto;
			padding-left: 10px;

			box-sizing: border-box;
		}

		a {
			font-family: inherit;
			color: #999999;
			span {
				cursor:pointer;
			}
		}
		.fileextension, .filesize, .filedate, .filedownloads {
			font-size: 12px;
			text-transform: uppercase;
		}

		.icon-file {
			flex-basis: 45px;
			max-width: 45px; /*ie10/11*/
			flex-shrink: 0;
		}

		.filename {
			flex: 1 0 200px;
			padding-left: 0;
		}

		.filedate {
			flex-basis: 102px;
			max-width: 102px; /*ie10/11*/
			flex-shrink: 0;
		}

		.filesize {
			flex-basis: 65px;
			max-width: 65px; /*ie10/11*/
			flex-shrink: 0;
		}

		.filedownloads {
			align-self: center;
			text-align: center;

			flex: 1 0 auto;
			max-width: 70px;

			background-color: @ci-cta-color;
			border-radius: 1rem;
			height: 2rem;
			min-width: 2rem;
			line-height: 1.7;
			padding: 0 .6rem;

			&:empty {
				display: none;
			}
		}


	}

	/* LABEL */
	.nrcopy-label {
		position: absolute;
		height: 100%;
		width: 100%;

		left: 0;
		top: 0;

		display: block;

		line-height: 200%;
		background-color: silver;
		padding: 2px 8px;

		text-transform: uppercase;
		text-align: center;
		font-size: 12px;

		color: black;

		&.copy {
			background-color: @ci-color;
		}
		&.error {
			background-color: @third-color;
		}
	}


	/* ICONS */

	// folder expand
	.icon-action-close, .icon-action-open {
		&:before {
			position: absolute;
			left: 0;
			display: block;

			width: 35px;
			height: @line-height;

			content: '';
			background: transparent url(/typo3conf/ext/ak_internal/Resources/Public/images/AKB-Menu-Expand.svg) no-repeat center center;
		}
	}

	.icon-action-open {
		color: @font-main-color;
		&:before {
			//transform: rotate(180deg);
			background-image: url(/typo3conf/ext/ak_internal/Resources/Public/images/AKB-Menu-Collapse.svg);
		}
	}


	// all icons
	.icon-folder, .icon-file, .icon-share, .icon-download {
		height: @line-height;
		background: transparent no-repeat center center;
	}

	// folder
	.icon-folder {
		display: block;
		float: left;
		width: 45px;
		background-image: url(/typo3conf/ext/ak_internal/Resources/Public/images/folder.svg);
	}
	.icon-action-open > .icon-folder {
		background-image: url(/typo3conf/ext/ak_internal/Resources/Public/images/folder-active.svg);
	}

	// file
	.icon-file, .icon-share, .icon-download {
		flex-basis: 45px;
	}
	.icon-file {
		background-image: url(/typo3conf/ext/ak_internal/Resources/Public/images/file.svg);
	}

	.icon-share, .icon-download {
		opacity: 0;
		pointer-events: none;
	}
	.icon-share {
		margin-left: 10px;
		background-image: url(/typo3conf/ext/ak_internal/Resources/Public/images/icon-share.svg);
	}

	.icon-download {
		background-image: url(/typo3conf/ext/ak_internal/Resources/Public/images/icon-download.svg);
	}

	.icon-empty {
		opacity: .6;
	}

	.file:hover {
		color: @font-main-color;
		background-color: #f7f7f7;

		a {
			color: @font-main-color;
		}

		.icon-file{
			background-image: url(/typo3conf/ext/ak_internal/Resources/Public/images/file-active.svg);
		}

		.icon-share, .icon-download {
			opacity: 1;
			pointer-events: auto;
		}

		.filedownloads {
			background-color: #000;
			color: #fff;
		}
		.icon-share {
			background-image: url(/typo3conf/ext/ak_internal/Resources/Public/images/icon-share-hover.svg);
		}
		.icon-download {
			background-image: url(/typo3conf/ext/ak_internal/Resources/Public/images/icon-download-hover.svg);
		}
	}


}