@margin: (@grid-gutter-width / 2);


.alert.alert-hollow {
	color: #000;
	background-color: transparent;
	border-color: #000;
}

.forum-user-avatar {

	margin-top: (@margin / 2);
	border: 1px solid @ci-color;

	.forum-user-name {
		display: block;
		margin: 8px;
		min-height: 34px;
	}
}

.avatar-icon {
	/*
	display: inline-block;
	width: 2em;
	height: 2em;
	//border-radius: 50%;

	text-align: center;
	line-height: 2em;

	font-family: @sidebar-font;
	font-size: 40px;

	filter: saturate(.4);
	-webkit-filter: saturate(.4);
	-ms-filter: saturate(.4);
	*/

	background-color: @ci-color!important;

	display: block;
	height: 5px;
	text-indent: -10000px;
	overflow: hidden;
}

.forum-user-name, .forum-description {
	font-family: @sidebar-font;
	font-size: @font-small;
	line-height: 1.3;
}

.secondary-action-link {
	font-size: @font-size-base;
	border-bottom: 1px solid @ci-color;
	&:hover {
		border-bottom-color: transparent;
	}
}

.tx-typo3forum {

	@margin: (@grid-gutter-width / 2);

	.TextOverflow {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.btn {
		font-family: inherit;
		text-align: left;
		min-width: 180px;
		margin-right: 3px;
	}

	h2 {
		margin-bottom: (@grid-gutter-width + 5);

		&.small-margin {
			margin-bottom: 27px;
		}

		&.no-margin {
			margin-bottom: 0;
		}

		small {
			display: block;
			margin-top: 5px;
		}

	}


	th:first-child, td:first-child {
		padding-left: 0;
	}
	th:last-child, td:last-child {
		padding-right: 0;
	}

	tr.is-closed {
		* {
			color: @font-second-color;
		}
	}

	td {
		vertical-align: middle!important;
	}

	h3 {
		margin-top: 0;
		margin-bottom: 5px;
	}

	.topic-name, .forum-name {

	}

	.topic-count, .post-count {
		min-width: 100px;
	}

	.topic-info {

		min-width: 140px;

		> * {
			display: inline;
			&:after{
				content: "\A";
				white-space: pre;
			}
		}

		time {
			font-family: @sidebar-font;
			font-size: @font-small;
		}

		.forum-user-name {
			display: inline-block;
			.TextOverflow;
			width: 100%;
			max-width: 150px;
		}
	}


	.icon-file {
		display: block;
		background: transparent url(/typo3conf/ext/ak_internal/Resources/Public/images/file.svg) no-repeat left center;

		width: 20px;
		height: 24px;
		float: left;
	}

	.post-file {

		display: inline-block;
		margin-right: 10px;
		white-space: nowrap;
		font-family: @sidebar-font;
		font-size: @font-small;

		&:hover {
			color: #000;
			.icon-file {
				background-image: url(/typo3conf/ext/ak_internal/Resources/Public/images/file-active.svg);
			}
		}
	}

	.pagination-wrapper {
		text-align: right;
	}


}


.markItUp {
	.markItUpHeader ul {
		.btn-group;
	}

	.markItUpButton {
		.btn;
		.btn-default;
		.glyphicon;

		position: relative;
		float: left;
		margin-right: 2px;
		height: 38px;
		line-height: 1.8;

		a {
			display: none;
		}
	}

	.tx-typo3forum-miu-bold {
		.glyphicon-bold;
	}
	.tx-typo3forum-miu-italic {
		.glyphicon-italic;
	}
	.tx-typo3forum-miu-quote {
		width: 38px;
		&:before {
			position: absolute;
			top: -23px;
			left: 8px;
			content: '“';
			font-size: 50px;
		}
	}
	.tx-typo3forum-miu-olist {
		.glyphicon-list;
	}
	
	.tx-typo3forum-miu-picture, .markItUpSeparator, .tx-typo3forum-miu-code, .tx-typo3forum-miu-smileys, .preview{
		display: none!important;
	}

	.markItUpEditor {
		width: 100%;
		height: 230px;
		resize: vertical;
	}

}

.user-list .user-info a.send-message { 
	font-family: 'Flama-Basic', sans-serif;
	text-transform: initial;
	margin-top: 5px;
}
