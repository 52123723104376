.flex-control-paging li a {
  outline: none;
}

.sisi-bottom-search  {
  .navbar-left {
    position: static;
  }
  
  .form-group {
    padding-top: 0px;
  }

  button {
    width: 5%;
    position: absolute;
    padding: 0px;
    right: 10px;
    top: 8px;
  }

  img {
      padding-top: 0px;
      position: static;
  }
}


input[type="checkbox"].form-control {
  display: inline;
  vertical-align: bottom;
  width: auto;
}

@media print {
  .sisi-bottom-search, .sisi-bottom-map, .sisi-footer {
    display: none;
  }
  .sisi-aside-left, .sisi-content {
    float: none;
  }
  a[href][onclick]::after, .logo-img a[href]::after {
    content: '';
    display: none !important;
  }
} 