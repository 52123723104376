@import "sisi-variables";
@import "variables";

#akheader {
	display: none;

	position: fixed;
	z-index: 10;
	left: 0;
	width: 100%;

	background-color: #fff;

	@media (min-width: @screen-md-min) {
		display: block;
	}

	.akheader-wrapper {
		display: flex;
	}

	.container {
		&:before, &:after {
			display: none!important;
		}
	}

	~.sisi-content {

		margin-top: 50px;

		@media (min-width: @screen-sm-min) {
			margin-top: 100px;
		}
		@media (min-width: @screen-md-min) {
			margin-top: 200px;
		}
		@media (min-width: @screen-lg-min) {
			margin-top: 260px;
		}

		transition: margin-top .5s ease;

		&.sisi-fixed &{
			background-color: red;
		}
	}

	&.sisi-fixed ~.sisi-content {
		margin-top: 130px;
		margin-bottom: 70px;

		@media (min-width: @screen-lg-min) {
			margin-top: 160px;
			margin-bottom: 100px;
		}
	}

}

#logo {

	flex: 0 0 33.33333%;
	transform: translateX(-11px);
	transition: transform .5s ease;

	#logo-svg, #logo-svg2 {
		width: 233px;
		height: 150px;
		transition: height .5s ease, width .5s ease;
	}

	a {
		display: block;
		background-color: white;
		padding-top: 55px;
		z-index: 10;
		outline: none;

		transition: padding-top .5s ease;
	}

	.sisi-fixed &{

		transform: translateX(0);

		#logo-svg, #logo-svg2 {
			height: 50px;
			width: 78px;
		}

		.logo-img-text {
			display: none;
		}

		a {
			padding-top: 25px;
		}
	}
}

.aknav {
	flex: 1;
	position: relative;
	margin: 0 (@grid-gutter-width / 2);

	.aknav-top {
		position: absolute;
		z-index: 1001;
		top: 0;
		right: 0;

		transition: top .5s ease;

		a:focus {
			background-color: transparent;
			outline: none;
		}

		.sisi-fixed & {
			top: -24px;

			a {
				color: @font-color-white;
			}

			#sisi-search-grey {
				.sisi-stroke-helper {
					stroke: @font-color-white;
				}
			}

		}
	}

	.navbar {
		margin-top: 55px;
		border: none;
		border-top: 25px solid @ci-color;

		transition: margin-top .5s ease;

		.sisi-fixed & {
			margin-top: 0;
		}
	}
}

@media (min-width: @screen-lg-min) {
}

@media (min-width: 992px)and (max-width: 1270px) {
	.sisi-nav-main {
		left: 30%;
	}
}

@media screen and (max-width: 1280px) {

	.sisi-nav-main {
		left: 34%;
	}
}
