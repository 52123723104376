

.tx-typo3forum .forum-post {

	@margin: (@grid-gutter-width / 2);

	display: flex;
	margin-bottom: @grid-gutter-width;

	.post-user {
		flex: 0 0 auto;
	}

	.forum-user-avatar {

		width: 100px;
		text-align: left;

		.avatar-icon {
			font-size: 30px;
		}
	}

	.post-content {
		flex: 1 1 100%;

		border-top: 1px solid @ci-cta-color;
		margin-top: (@margin / 2);
		margin-left: @margin;
		padding-top: 10px; /* one line with username */

		ul {
			list-style: none;
			li {
				padding-left: 20px;
				&:before {
					position: absolute;
					content: '–';
					margin-left: -20px;
				}
			}
		}

		blockquote {
			margin-bottom: 20px !important;
		}

		p {
			margin-bottom: 0;
		}
	}

	.post-info {
		display: flex;
		justify-content: space-between;

		margin-top: 10px;

		font-family: @sidebar-font;
		font-size: @font-small;
		color: @font-second-color;

		* {
			font-family: inherit;
			color: inherit;
		}

		.post-date {
			flex: 0 1 auto;
		}

		.post-attachments {
			flex: 1 1 auto;
			margin-left: @margin;
			clear: both;

			overflow: hidden;
		}

		.glyphicon {
			margin-left: 5px;
		}
	}

}

#post, #topic {

	@margin: (@grid-gutter-width / 2);

	.form-action {
		margin-top: @grid-gutter-width;
	}

	.post-file {
		margin-bottom: @margin;
	}
}