@import "sisi-variables";
.tx-sisi-seminar{
  .sisi-seminar-rubrik{
    letter-spacing:.1rem;
    text-transform: uppercase;
  }
  .form-group a{
    border-bottom:solid thin @ci-cta-color;
  }
  h1{
    color:@font-main-color;
  }
  .sisi-collapse{
    color: @font-second-color;
  }
  ul{
    margin-left:0;
    padding-left:0;
    border-bottom:solid thin @font-second-color;
  }
  .list-group-item{
    border: none;
    border-radius: 0;
    padding-left: 0;
  }
  .sisi-seminar-titel{
    padding-top: 2rem;
    a{
    font-size:@main-headline;
    color:@font-main-color;
    }
    a:hover{
      text-decoration: none;
    }
  }
  .sisi-label-align-left{
    padding-left: 0;
  }
  #photo, .btn-file{
    cursor: pointer;
  }

  #seminargroup.padding-left25 {
    padding-left: 35px;
  }
  h1 {
   .clearfix();
   margin-bottom: 0;
   padding-bottom: 0; 
   & > div {
      padding-left: 35px;
      .chevron-down {
        margin-left: -35px;
      }
    }
  }
}
.sisi-seminar-rubrik{
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}
.radio label, .checkbox label{
  letter-spacing: .1rem;
}

.sisi-seminar-termin-image {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  img {
    max-height: 400px; 
    width: auto;
    max-width: 100%;
  }
}
