.tx-typo3forum {

	@margin: (@grid-gutter-width / 2);

	.topic-header, .forum-header {

		a {
			&:after {
				display: inline-block;
				content: '/';
				margin: 0 .3ch;
			}

			&:last-child:after {
				display: none;
			}
		}

	}

	.topic-action, .forum-action, .form-action, .message-action {
		display: block;
		overflow: hidden;
		margin-bottom: 10px;
	}

}