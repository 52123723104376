@import "sisi-variables";
@import "variables";

body, html {
	margin: 0;
	padding: 0;
}

.ce-table {
	width: 100%;
	td {
		padding: 10px 0;
	}
}

.tx-go-maps-ext {
	width: 100%;
}

.js-map {
	width: 100%;
}

.sisi-header-mobile, #mobile-main-navi {
	display: none;
}

.sisi-seminar-filter-mobile-str {
	display: none;
}

.navbar-brand {
	display: none;
}

.sisi-upload-trigger {
	cursor: pointer;
}

.no-padding {
	padding-left: 0;
	padding-right: 0;
}

.padding-left25 {
	padding-left: 25px;
}

.margin-bottom1 {
	margin-bottom: 3rem;
}

.internal-link-right {
	border-bottom: solid thin @ci-cta-color;
	float: right;
}

h1,
.h1 {
	font-size: 34px;
	font-weight: 300;
	padding-bottom: 2.2rem;
	font-family: 'Flama-Light';
	a {
		font-family: 'Flama-Light';
	}
}

h2,
.h2 {
	font-size: 22px;
	font-family: "Source Sans Pro";
	font-weight: normal;
	a {
		font-family: "Source Sans Pro";
	}
	
}

h3,
.h3 {
	font-size: 22px;
	font-family: 'Flama-Light';
	a {
		font-family: 'Flama-Light';
	}
}

h4,
.h4 {
	font-size: 14px;
	font-family: 'Flama-Light';
	a {
		font-family: 'Flama-Light';
	}
}

h5,
.h5 {
	font-size: 12px;
	font-family: 'Flama-Light';
	a {
		font-family: 'Flama-Light';
	}
}

h6,
.h6 {
	font-size: 10px;
	font-family: 'Flama-Light';
	a {
		font-family: 'Flama-Light';
	}
}

hr {
	border-color: #999999;
}

a {
	font-family: 'Flama-Basic';
}

.sisi-startseite .default-bold {
	font-size: 1.4rem;
	padding-bottom: 15px;
	color: @font-main-color;
	letter-spacing: 0.1rem;
	text-transform: uppercase;
	padding-top: 15px;
}

.sisi-RelatedFiles {
	border-bottom: solid thin @ci-cta-color;
	padding-bottom: 2px;
	font-size: 85%;
	
}

.default-bold {
	font-weight: 400;
	padding-bottom: 15px;
}

a:hover, a:focus {
	text-decoration: none;
}

.font-small {
	font-size: @font-small;
}

.font-medium {
	font-size: @font-medium;
}

.ci-cta-color {
	color: @ci-cta-color;
}

.grey-color {
	color: @font-second-color;
}

.second-bgcolor {
	background-color: #9BC9CF;
}

.third-bgcolor {
	background-color: #AFD1D2;
}

.second-color {
	background-color: #9BC9CF;
}

.third-color {
	background-color: #AFD1D2;
}

.font-color-white {
	color: @font-color-white;
	
}

.icon-big {
	font-size: @icon-big;
	padding-left: 0;
}
img + .icon-big {
	margin-top: 1rem;
}
.chevron-down {
	background-image: url("../images/AKB-Menu-Expand.svg");
	background-repeat: no-repeat;
	display: inline-block;
	width: 25px;
	height: 22px;
	background-position: -5px -7px;
	transition: transform 0.7s;

	.sisi-collapse[aria-expanded=true] & {
		transform: rotate(180deg);
		transition: transform 0.7s;
	}
}

.icon-pdf {
	background-image: url("../images/Icon-AKB-Dokument.svg");
	background-repeat: no-repeat;
	display: block;
	width: 50px;
	height: 70px;
	margin-bottom: 1.5rem;
}

.sisi-pulldown {
}

.sisi-collapse {
	cursor: pointer;
}

.sisi-ci-color-text {
	color: @ci-cta-color;
}

img {
	max-width: 100%;
	height: auto;
}

ul.ce-bullets {
	padding-left: 20px;
}

.sisi-membersearch-offset {
	margin-top: 100px;
}

#page-155 .sisi-member-form {
	display: block !important;
}

.tx-sisi-members {
	max-width: 100%;
}

.tx-sisi-members-popup {
	padding-right: 20px;
}

#membersearch {
	width: ~"calc(33.33333% - 33px)";
	
}

div {
	box-sizing: border-box;
}

body {
	font-family: "Source Sans Pro", sans-serif;
	line-height: 2.5rem;
	font-size: 1.6rem;
}

.sisi-logo {
	height: 125px;
	padding-top: 50px;
	img {
		position: absolute;
		left: -45px;
	}
}

.sisi-header {
	height: 200px;
}

.sisi-header-sub {
	height: 245px;
}

.sisi-headline {
	a {
		font-family: 'Flama-Light';
		line-height: 32px;
	}
}

.sisi-aside-left {
	/*margin-top:100px;*/
	z-index: 1;
	font-family: @sidebar-font;
	select {
		font-family: @sidebar-font;
	}
	button {
		font-family: @sidebar-font;
	}
	a {
		font-family: @sidebar-font;
	}
	a.glyphicon {
		font-family: "Glyphicons Halflings";
	}
}

.sisi-content {
	.clearfix;
}

.sisi-startseite {
	margin-top: 45px;
	.sisi-headline {
		padding-top: 0;
		padding-bottom: 8px;
		line-height: 32px;
		
	}
}

.sisi-banner {
	position: relative;
	margin-bottom: 3rem;
}

.sisi-text-wrapper {
	position: absolute;
	bottom: 0;
	width: 80%;
	min-height: 20%;
	background-color: @bg-color-white;
	opacity: 0.8;
	padding: 20px;
}

.sisi-startseite-right {
	padding-bottom: 1.5rem;
	background-color: @bg-color-grey;
}

.sisi-startseite-left {
	.sisi-more-link {
		display: none;
	}
	.templateLayout-7 .sisi-more-link {
		display: initial;
	}	
	.list-group-item {
		padding-bottom: 1rem;
	}
	.ce-above .ce-gallery {
		margin-top: 0;
	}
}

.sisi-bigest-font {
	font-size: 3.6rem;
	font-weight: 300;
	line-height: 4.8rem;
	margin-bottom: 18px;
	font-family: 'Flama-Light';
}

.sisi-headline {
	font-size: @font-medium;
	line-height: 32px;
	font-weight: 300;
	padding-top: 1.875rem;
	padding-bottom: 1.875rem;
}

.sisi-headline-no-margin {
	font-size: @font-medium;
	font-weight: 300;
	padding-top: 1.875rem;
	line-height: 32px;
}

.sisi-font-wide {
	letter-spacing: .1rem;
}

.sisi-label-lp {
	font-size: @medium-headline;
	padding-top: 1.875rem;
	padding-bottom: 1.875rem;
}

.list-group-item, .checkbox-label-wrapper {
	padding-bottom: .1rem;

	border: none;
	border-radius: 0;
	padding-left: 0;

	input[type="checkbox"]{
		margin-right: 1rem;
	}

}

ul {
	margin-left: 0;
	padding-left: 0;
}

.list-group {
	ul {
		margin-left: 0;
		padding-left: 0;
		border-bottom: solid thin @font-second-color;
	}
}

.sisi-ce-spacer {
	padding-bottom: 2rem;
}

.sisi-highlights-bg-color {
	width: 100%;
	height: 100%;
	background-color: @bg-color-grey;
}

.sisi-highlights-title {
	margin-bottom: 4.5rem;
	font-size: 1.4rem;
	letter-spacing: 0.1rem;
	text-transform: uppercase;
	a {
		font-family: inherit;
	}
}

.row.sisi-highlights-bg-color {
	background-color: @bg-color-grey;
	margin-left: 0;
	margin-right: 0;
}

.sisi-aside-content {
	border-top: solid thin @ci-cta-color;
	margin-top: 15rem;
}

.sisi-aside-content-headline {
	font-size: 120%;
	color: @ci-cta-color;
}

.sisi-footer-wrapper {
	background-color: @bg-color-grey;
	padding: 5rem 0;
	margin-top: 12rem;
}

.sisi-contact-approach {
	min-height: 40rem;
}

.sisi-footer {
	a {
		color: @font-main-color;
	}
	li {
		list-style: none;
	}
	.sisi-li-first {
		font-weight: bold;
		padding-bottom: .5rem;
		a {
			color: @font-main-color;
		}
	}
	.navbar {
		z-index: 0;
	}
}

.sisi-main-container {
	margin-bottom: 10px;
}

form button {
	border: none;
	background-color: white;
	box-shadow: none;
}

.sisi-footer-first a, .sisi-footer-second a, .sisi-footer-fourth a, .sisi-footer-third a {
	color: @font-second-color;
}

.sisi-bottom-map {
	padding-right: 0;
}

.tx-go-maps-ext {
	float: right;
}

//* News*//
.news-single {
	.extra, .lead, .thumbnail {
		display: none;
	}
}
.sisi-pagination, .f3-widget-paginator{
  li{
	display:inline;
	color:@font-second-color;

	a{
	  padding: 10px;
	  color:@font-second-color;
		&:hover {
			color:@ci-cta-color;
		}
	}
  }

  .active, .current{

	  color:@ci-cta-color;

  }
  .previous{
	a{
	  padding-left:0;
	  margin-left:0
	}
  }
}

.sisi-startseite-right {
	.sisi-news-item {
		border-bottom: solid thin @btn-default-border;
		padding-left: 0 !important;
	}
}

.sisi-news-item {
	border-bottom: solid thin @btn-default-border;
	margin: 2rem 0;
	.sisi-startseite-right &, &.sisi-layout1 {
		padding-left: 0 !important;
	}
	.extra time {
		display: none;
	}
	&:first-child {
		border-top: solid thin @btn-default-border;
		padding-top: 2rem;
	}
}
.news .nav {
	margin-top: 46px;
}

.sisi-label-info {
	color: @font-main-color;
	font-size: 100%;
	display: inline;
	padding: 0;
	font-weight: 500;
}
.sisi-date {
	display: none;
}
.sisi-layout2 .sisi-date {
	display: inline;
	#c45 & {
		display: none;
	}
}
.sisi-more-link {
	padding-bottom: 2px;
	border-bottom: solid thin @ci-cta-color;
}

.news {
	a {
		color: @font-main-color;
		&.btn {
			height: auto;
			padding: 0px;
			.sisi-more-link;

			&:hover {
				background-color: inherit;
				color: inherit;	
			}
		}
	}
	p {
		color: @font-second-color;
	}
	h4 {
		font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
	}
	
	img {
		margin-top: 0
	}
	.img-dummy {
		display: none;
	}
	.article {
		p {
			color: @font-main-color;
		}
	}
}
.news-list-view {
	.clearfix;
}
.news-list-item {
	img {
		max-height: 220px;
		width: auto;
	}
	h3 {
		-moz-hyphens: auto;
		-webkit-hyphens: auto;
		-ms-hyphens: auto;
		hyphens: auto;
		word-wrap: break-word;
		margin-top: 0px;
		.templateLayout-7 & {
			margin-bottom: 4.5rem;
		}
	}
	.topnews h3{
		margin-top: @line-height-computed!important;
	}
	.templateLayout-7 & {
		background-color: #f5f3f4;
		border: 0;
	}
	.templateLayout-5 & {
		padding-left: 0;
	}
}

.sisi-news-teaser {
	color: @font-second-color;
}

.sisi-news-item-border {
	/*border-bottom:solid thin @font-second-color;*/
	clear: both;
	
}



.sisi-item-border {
	border-top: solid thin @font-second-color;
	margin-bottom: 35px;
}

.news-single {
	.thumbnail {
		border: none;
	}
	.news-related-files {
		margin-top: 2.5rem;
		padding-top: 0.5rem;
		border-top: solid thin @font-second-color;
		h4:after {
			background-image: url(/typo3conf/ext/ak_internal/Resources/Public/images/Icon-AKB-Dokument.svg);
			background-repeat: no-repeat;
			width: 50px;
			height: 70px;
			display: block;
			content: ' ';
			margin-top: 1rem;
		}
		li {
			list-style: none;
		}
		a {
			.download;
		}
		.news-related-files-size {
			display: none;
		}
	}
	.flexslider-news {
		margin-top: 20px;
	}
	.link-back {
		margin-top: 60px;
	}
}

.extra {
	margin-top: 30px;
	letter-spacing: .1rem;
}

.social {
	display: none;
}

.sisi-event-media {
	margin-top: 25px;;
}

.sisi-fal-media-wrapper {
	padding-bottom: 15px;
}


.ce-bodytext {
	ul {
		padding-left: 20px;
	}
	li {
		font-size: 16px;
	}
	.ce {
		margin-top: 100px;
		margin-bottom: 100px;
	}
	
}

.ce-table {
	margin-bottom: 15px;
	td {
		padding-right: 10px;
	}
}

.ce-above {
	.ce-gallery {
		margin-bottom: 0;
		margin-top: 20px;
	}
}

.panel-body {
	.ce-gallery {
		margin-bottom: 45px;
	}
}

.ce-uploads {
	
}

.col-lg-3 {
	.internal-link-kalender {
		font-size: @font-small;
		background-image: url("../images/Icon-AKB-Kalender.svg");
		background-repeat: no-repeat;
		display: block;
		padding-top: 65px;
		margin-top: 20px;
		border-bottom: solid thin @ci-cta-color;
	}
	.external-link, .external-link-new-window, .internal-link, .internal-link-new-window, .mail {
		font-size: @font-small;
		border-bottom: solid thin @ci-cta-color;
	}
	.ce-uploads-fileName {
		padding-left: 15px;
		font-size: @font-small;
		background-image: url("../images/download-arrow.svg");
		background-repeat: no-repeat;
		background-position: 0 6px;
	}
	span {
		.download {
			border-bottom: solid thin @ci-cta-color;
			font-family: "Source Sans Pro";
		}
	}
}

/*external-link, .external-link-new-window, .internal-link, .internal-link-new-window col-9*/
.internal-link-kalender {
	background-image: url("../images/Icon-AKB-Kalender.svg");
	background-repeat: no-repeat;
	display: block;
	padding-top: 65px;
	margin-top: 20px;
	border-bottom: solid thin @ci-cta-color;
}

.external-link, .external-link-new-window, .internal-link, .internal-link-new-window, .ce-uploads-fileName, .mail {
	border-bottom: solid thin @ci-cta-color;
	font-family: "Source Sans Pro";
}

.download {
	font-family: "Source Sans Pro";
	font-size: 16px;
	/*background-image: url("../images/download-arrow.svg");
	background-repeat: no-repeat;
	background-position: 0 3px;
	padding-left: 10px;*/
	border-bottom: solid thin @ci-cta-color;
	margin-left: 12px;

	&:before {
		display: inline-block;
		content: '';
		background-image: url("../images/download-arrow.svg");
		background-repeat: no-repeat;
		background-position: 0 3px;
		width: 12px;
		height: 16px;
		margin-left: -12px;
	}
}

.sisi-content-right {
	.download {
		font-size: 85%;
	}
}

.sisi-content-left {
	.download {
		font-size: 100%;
	}
}

.sisi-news-item {
	.articletype-0 {
		padding-right: 1rem;
		padding-bottom: 4rem;
	}
	
}

.ce-gallery figcaption {
	font-size: @font-small;
}

.ce-gallery + .ce-bodytext {
	margin-top: 45px;
}

/****************** Accordion ***************/
.panel-body {
	padding-left: 0;
}

.panel-default {
	border: none;
}

.panel-default > .panel-heading {
	border: none;
	background-color: white;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.panel {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	border-bottom: solid thin @font-second-color;
}

.panel-heading {
	padding-left: 0;
}

.accordion-toggle .collapsed {
	color: yellow;
}

.panel-title .accordion-toggle {
	color: red;
}

.container {
	position: relative;
}

.sisi-footer-inner {
	margin-top: 4rem;
}
.flex-direction-nav a:before{
  border:none;
  display: none;
}
.flex-direction-nav a{
  color: transparent;
  font-size:0;

}
.flex-next{
  background-image:url("../images/Pfeil_rechts.svg");
  background-repeat:no-repeat;
  background-size:16px;
  background-position: center;
  border:none;
}
.flex-prev{
  background-image:url("../images/Pfeil_links.svg");
  background-repeat:no-repeat;
  background-size:16px;
  background-position: center;
  border:none;
}
.flex-control-paging li a{
  background-color:@ci-color;
}
.flex-control-paging li a.flex-active{
  background-color:@ci-cta-color;
}



/****************** Gallery ***************/
.ce-gallery {

	&, .ce-outer, .ce-inner, .ce-column {
		max-width: 100%;
	}
}
.ce-gallery, .flexslider {

	.caption-wrapper {
		position: relative;
		width: 100%;
		padding: 1rem 0;

		figcaption {
			display: block;
			float:left;
			max-width: 75%;
			font-size: 13px;
			line-height: 1.4;
		}

		.gallery-item-copyright {
			display: block;
			width: 25%;
			float: right;
			text-align: right;

			&:after {
				display: block;
				content: '©';
			}
			
			&:hover{
				&:after{
					visibility: hidden;
				}
			}
			

			+ figcaption {
				float: left;
				max-width: 75%;
			}
		}


	}






}


#gallery-copyright-text {
	position: absolute;
	background:white;
	pointer-events: none;
	font-size: 13px;
	line-height: 1.4;
	color:@ci-cta-color;
	text-align:right;
}