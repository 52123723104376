@import 'sisi-variables';

.mega-dropdown-menu {
	.col-lg-4:nth-child(4) {
		clear: both;
	}
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////7

.sisi-mobile {
	display: none;
}

#sidebar-wrapper .aktive-level1.sisi-down {

	background-image: none;
}

#sidebar-wrapper-mobile .aktive-level1.sisi-down {

	background-image: none;
}

.sisi-nav-main > div {
	border-top: 25px solid @ci-color;
	padding-left: 30px;
}

body.aktive a {
	color: yellow;
}

body {

}

.sisi-search-trigger {
	background: transparent;
	width: 0;
	border: none;
	-webkit-transition: width 0.5s; /* Safari */
	transition: width 0.5s;
}

.sisi-search-trigger.sisi-search-opening {
	width: 200px;
	border: solid thin @second-color;
}

.glyphicon-search {
	cursor: pointer;
}

.form-group input {
	outline: none;
}

.navbar {
	z-index: 1000;
}

.sisi-navi-head:hover {
	ul {
		display: block;
		margin-top: 0; // remove the gap so it doesn't close

	}

}

li.dropdown > a {
	color: @font-main-color;
}

.aknav-top {
	a {
		font-size: @font-small;
		color: @font-second-color;
	}
	.sisi-last a {
		color: @font-main-color;
	}
	.active {
		a {
			color: @ci-color;
		}
	}

	a:hover {
		color: @ci-color;
	}
}

#sisi-navbar {

	.navbar-nav {
		margin-top: -1.5rem;

		.nav-item li:last-child {
			color: yellow;
		}
	}

	a {
		color: @font-main-color;
	}

	.sisi-navi-head {

		&.aktive > a {
			color: @ci-color;
		}

		&:hover {
			background-color: @ci-cta-color;
			color: @font-color-white;

			&.aktive > a, a:hover {
				color: @font-main-color;
			}

			a {
				background-color: @ci-cta-color;
				color: @font-color-white;
			}

			.dropdown-menu > .aktive > a, .aktive > a, .dropdown-menu > li > a:hover {
				color: @font-main-color;
			}

			ul {
				padding: 0 0 5px 0;
				margin: 0;
			}
		}
	}
	.dropdown-menu {
		background: @ci-cta-color;
		color: @font-color-white;
		border: none;
		box-shadow: none;
		border-radius: 0;
		top: 0;
		min-width: 230px;
		z-index: -1;

		.sisi-li-first {
			padding-top: 50px;
		}

		> li > a {
			color: @font-color-white;
		}
		> li > a:hover {
			background-color: @ci-cta-color;
		}

		ul.sisi-ul-sub {
			padding: 0px 20px 0px 30px;
			list-style: none;
			/*overflow: hidden;
			max-height: 0px;*/
			display: none;

			.sisi-li-first {
				padding-top: 0px;
			}
			.sisi-li-last {
				padding-bottom: 8px;
			}
		}
	}

}

#sidebar-wrapper {
	margin-top: 1.5rem;

	.sisi-dropdown-menu {
		display: none;
	}

	ul {
		padding-left: 0;
	}

	li {
		list-style: none;
		padding-top: 5px;
		padding-bottom: 5px;
		border-bottom: solid thin @ci-cta-color;
	}

	.sisi-li-last-level1 {
		border-bottom: none;
		> a {
			color: @ci-cta-color;
		}
	}

	.sisi-dropdown > a {
		line-height: 1.3;
		display: inline-block;
		width: calc(100% - 55px);
	}

	a {
		color: @font-second-color;
		/*    font-family: @sidebar-font;*/
	}

	a:hover {
		text-decoration: none;
	}

	a.aktive {
		color: @font-main-color;
	}

	.aktive-level2 {
		a {
			color: @font-main-color;
		}
	}

	.sisi-arrow-down {
		margin-top: 7px;
		cursor: pointer;

	}

	.sidebar-navi-sub-level2 {
		margin-top: 6rem;

		.aktive > a, a:hover {
			color: @font-main-color;
			text-decoration: none;
		}

	}
	.sidebar-navi-sub-level3 {
		margin-left: 50px;
		padding-top: 10px;
		li {
			border: none;
		}
	}
	.sisi-down {
		background-image: url('../images/AKB-Menu-Expand.svg');
		background-repeat: no-repeat;
		background-position: 95% 5px;
	}
	.sisi-up {
		background-image: url('../images/AKB-Menu-Collapse.svg');
		background-repeat: no-repeat;
		background-position: 95% 5px;
	}

}

#sidebar-wrapper-mobile {

	margin-top: 1.5rem;

	.sidebar-navi-sub-level2 {
		display: none;
	}

	.sisi-dropdown-menu {
		display: none;
	}
	ul {
		padding-left: 0;
	}
	li {
		list-style: none;
		padding-top: 3px;
		padding-bottom: 3px;
		border-bottom: solid thin @ci-cta-color;
	}

	.sisi-li-last-level1 {
		border-bottom: none;
		> a {
			color: @ci-cta-color;
		}
	}
	a {
		color: @font-second-color;
		/*    font-family: @sidebar-font;*/
	}
	a:hover {
		text-decoration: none;
	}
	a:aktive {
		color: @font-main-color;
	}
	.aktive-level2 {
		a {
			color: @font-main-color;
		}
	}
	.sisi-arrow-down {
		margin-top: 7px;
		cursor: pointer;

	}
	.sidebar-navi-sub-level2 {
		margin-top: 6rem;
		padding-left: 20px;
	}
	.sidebar-navi-sub-level3 {
		li {
			border: none;
			padding-left: 10px;
		}
	}
	.sisi-down {
		background-image: url('../images/AKB-Menu-Expand.svg');
		background-repeat: no-repeat;
		background-position: 97% 5px;
		background-size: 30px 30px;
	}
	.sisi-up {
		background-image: url('../images/AKB-Menu-Collapse.svg');
		background-repeat: no-repeat;
		background-position: 97% 5px;
		background-size: 30px 30px;

	}

}

.sisi-navi-bottom-small {
	padding-right: 10%;
	a {
		color: @font-main-color;
	}
	.navbar-nav > li > a:hover {
		background-color: transparent;
	}
	.navbar-nav > li > a:hover {
		color: @ci-cta-color;
		background-color: transparent;
	}

	ul {

		float: right;
	}
}

@media (min-width: 768px) {

	.aknav-top {

		.navbar-nav > li > a {
			padding-top: 25px;
			padding-bottom: 0px;
		}
		.navbar-nav > li > a:hover {
			background-color: transparent;
		}
	}
}

#breadcrumb {

	margin-top: 1.5rem;

	ul {
		list-style: none;
	}

	li {
		display: inline;

		> * {
			display: inline;
			&:after {
				content: ' > ';
				color: @font-main-color;
			}
		}

		a {
			color: @ci-cta-color;
		}

		span {
			color: @ci-color;
			cursor: default;
		}

		&:last-child > *:after {
			display: none;
		}

	}

}

#sidebar-wrapper {
	margin-top: 0;
}

#sidebar-wrapper .sidebar-navi-sub-level2 {
	margin-top: 0;
}