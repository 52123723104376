.simple-typo3-messages {
	> div {
		border: 1px solid @font-second-color;
		padding: @form-group-margin-bottom;

		margin-bottom: (@form-group-margin-bottom * 2);
	}
}


.tx-felogin-pi1 {

	clear: both;
	overflow: hidden;

	a {
		font-size: @font-size-base;
		border-bottom: 1px solid @ci-color;
		&:hover {
			border-bottom-color: transparent;
		}
	}

}

.tx-femanager {
	.help-block,
	.help-block,
	.help-block,
	.help-block {
		margin-top: -1.2rem;
	}
}

.femanager_list {
	margin-top: @grid-gutter-width;
}

.user-list {

	@small-gutter: 10px;
	.TextOverflow {
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	display: flex;
	flex-wrap: wrap;
	position: relative;

	margin: 0 (-@small-gutter / 2);

	@media (max-width: @screen-sm-max) {
		max-width: calc(100% - 20px);
	}

	.user-item {

		display: block;
		flex: 0 0 calc(50% - 10px);
		max-width: calc(50% - 10px);

		@media (min-width: @screen-sm) {
			flex-basis: calc(100% - 10px);
			max-width: calc(100% - 10px);
		}
		@media (min-width: @screen-md) {
			flex-basis: calc(50% - 10px);
			max-width: calc(50% - 10px);
		}
		@media (min-width: @screen-lg) {
			flex-basis: calc(33.33333% - 10px);
			max-width: calc(33.33333% - 10px);
		}

		list-style: none;
		margin: @small-gutter (@small-gutter / 2);

	}

	.user-info{
		position: relative;
		border: 1px solid @ci-cta-color;
		padding: (@small-gutter / 2) @small-gutter;

		min-height: 120px;

		&:before {
			display: block;
			position: absolute;
			width: calc(100% + 2px);
			left: -1px;
			top: -1px;
			content: '';
			height: 15px;
			background-color: @ci-color;
		}

		* {
			font-family: 'Flama-Light', sans-serif;
		}

		h4 {
			font-size: 1.6rem;
			text-align: right;
			margin-top: 10px;
			min-height: 34px;

			font-family: 'Flama-Basic', sans-serif;

			small {
				display: block;
				text-transform: uppercase;
				margin-top: 5px;
			}
		}

		a {
			text-transform: lowercase;
			.TextOverflow();
		}

	}

}

.azPaginationAction {
	position: fixed;
	z-index: 1002;
	top: 50%;
	right: 0;

	transform: translateY(-50%);

	background-color: @ci-cta-color;
	overflow: hidden;
	list-style: none;

	line-height: 1.2;

	li {

		&:first-child {

			a {
				padding-top: 8px;
				padding-bottom: 8px;
				font-size: 1rem;
				font-family: 'Flama-Basic', sans-serif;
			}
		}

		&:last-child {
			padding-bottom: 6px;
		}
	}

	a {
		display: block;
		text-align: center;
		text-transform: uppercase;
		white-space: nowrap;

		font-size: 1.4rem;
		line-height: 1;
		padding: 2px 0;
		min-width: 35px;

		font-family: 'Flama-Light', sans-serif;

		&.disabled {
			opacity: .3;
			pointer-events: none;
		}

		&.active {
			background-color: @ci-color;
			color: #fff;
			font-family: 'Flama-Basic', sans-serif;
		}

	}
}