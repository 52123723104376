.tx-typo3forum {

	@margin: (@grid-gutter-width / 2);
	.TextOverflow {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	overflow: hidden;

	.new-conversation-button {
		display: inline-block;

		margin-top: @grid-gutter-width;

		i {
			padding: 13px 0;
			border: 1px solid #000;
			margin-right: 7px;
			transition: all .2s;
			font-family: @font-regular!important;

			height: 44px;
			width: 44px;
			text-align: center;

			&:before {
				line-height: .5;
				font-size: 25px;
				transform: translateX(1px);
				display: inline-block;
			}
		}

		&:hover i {
			background-color: #000;
			color: #fff;
		}

	}

	&#forum-message-list {
		padding-bottom: @margin;
	}

	.message-list {
		margin-top: (@grid-gutter-width * 2);
	}

	.message-conversation {
		display: flex;
		border-bottom: 1px solid @ci-cta-color;
		padding: (@margin / 2) 0;

		.avatar-icon {
			display: none;
			flex: 0 0 auto;
			font-size: 22px;
		}

		.message-conversation-info {
			flex: 1 1 auto;

			* {

				color: #666;

				line-height: 1;
				.TextOverflow;

				&:first-child {
					margin-top: 3px;
				}
			}

		}

		.forum-user-name {
			font-size: inherit;
		}
		time {
			font-size: @font-small;
		}

		&:hover, &.active {
			.message-conversation-info * {
				color: #000;
			}
		}
		&.active {
			.message-conversation-info * {
				font-family: @font-regular;
			}
		}
	}







	.message-timeline {
		.clearfix;
	}

	.single {

		@bg-color-grey: #efefef;

		position: relative;

		display: block;
		width: 60%;
		min-width: 300px;
		float: left;
		clear: both;

		margin-bottom: @margin;

		&:before {
			content: "";
			position: absolute;
			display: block;
			width: 0;

			top: -20px;
			right: 30px;
			bottom: auto;
			left: auto;

			border-style: solid;
			border-width: 20px 0 0 25px;
			border-color: transparent @bg-color-grey;
		}

		&.right {
			float: right;
		}

		p {
			margin: 0;
		}

		.message-content {
			line-height: 1.3;
			background-color: @bg-color-grey;
			padding: (@margin / 2) @margin;
		}

		&.right {
			&:before {

				right: auto;
				left: 30px;

				border-width: 20px 25px 0 0;
				border-color: transparent @ci-cta-color;
			}
			.message-content {
				background-color: @ci-cta-color;
			}
		}

		.message-information {

			margin-top: 5px;
			padding-left: @margin;

			font-family: @sidebar-font;
			font-size: @font-small;
			line-height: 1;

			opacity: 0;
			transition: opacity .2s;

			.TextOverflow;

			* {
				font-size: inherit!important;
			}

		}

		&:hover {
			.message-information {
				opacity: 1;
			}
		}

		&.right + .single.right,
		&:not(.right) + .single:not(.right){
			margin-top: -41px;
			&:before {
				display: none;
			}
		}


	}

	#createPost {

		textarea, input[type=text] {

			font-size: 16px;
			padding: 11px 12px 10px;
			line-height: 22px;

			&:focus {
				outline: none;
				border-color: @ci-color;
				box-shadow: none;
			}
		}

		input[type=text] {
			height: 45px;
		}

		textarea {
			display: block;

			min-height: initial;
			height: auto;

			margin: 0;
			resize: none;

			box-sizing: content-box;
			overflow: hidden;

			&:focus {
				outline: none;
				border-color: @ci-color;
				box-shadow: none;
			}

			max-width: calc(100% - 26px);
		}

		#newMessagerRecipient {
			flex: 1 0 100%;
		}

		.message-box {
			display: flex;
			align-items: flex-end;

			> div:first-child {display: none;}

			.control-group {
				flex: 1 1 auto;
			}

			.btn {
				margin-top: 0;
			}
		}

		.btn {

			min-width: initial;

			&:focus {
				box-shadow: none;
				outline: none;
			}
		}
	}

}