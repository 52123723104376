@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600);

@font-face {
  font-family: 'Flama-Light';
  src: url('fonts/Flama-Light.eot');
  src: url('fonts/Flama-Light.eot?#iefix') format('embedded-opentype'),
  url('fonts/Flama-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Flama-Basic';
  src: url('fonts/Flama-Basic.eot');
  src: url('fonts/Flama-Basic.eot?#iefix') format('embedded-opentype'),
  url('fonts/Flama-Basic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@ci-color:#9BC9CF;
@ci-cta-color:#dfecec;
@second-color:#e4002c;
@third-bgcolor:#F2998B;
@second-bgcolor:#F2998B;
@third-color:#e4002c;
@font-main-color:#000000;
@font-second-color:#999999;
@font-color-white:#ffffff;
@bg-color-grey:#F5F6F8;
@bg-color-white:#ffffff;


@font-small:85%;
@main-headline:120%;
@medium-headline:120%;
@font-medium:155%;
@icon-big:200%;
@border-radius-base:0;

@font-regular: 'Flama-Basic', sans-serif;
@sidebar-font: 'Flama-Light', sans-serif;